.form > h2 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  
  .form > div,
  #treeview {
    display: inline-block;
    vertical-align: top;
  }
  
  #selected-employees {
    margin-top: 20px;
  }
  
  .selected-container {
    padding: 20px;
    margin-left: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    font-size: 115%;
    font-weight: bold;
  }
  
  /* .dx-list-item-content {
    padding-left: 0;
  } */
  
  .options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    margin-top: 20px;
    box-sizing: border-box;
  }
  
  .caption {
    font-size: 18px;
    font-weight: 500;
  }
  
  .option {
    width: 24%;
    margin-top: 10px;
    margin-right: 9px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .options-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  
  .editor-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .editor-container > * {
    width: 100%;
  }
  
  .option:last-of-type {
    margin-right: 0;
  }

  .notification-save{
    margin: 20px 00px 0px 00px;
    background-color: #d71109;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  .notification-save:hover{
    margin: 20px 00px 0px 00px;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  .notification-cancel{
    margin: 20px 00px 0px 00px;
    background-color: #d71109;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  .notification-cancel:hover{
    margin: 20px 00px 0px 00px;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  .notification-back{
    margin: 20px 00px 0px 00px;
    background-color: #d71109;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  .notification-back:hover{
    margin: 20px 00px 0px 00px;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
 